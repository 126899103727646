import React from "react"
import { theme } from "../Styles/theme"
import { Global } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"
import Header from "../Components/header"
import LandingLayout from "../Components/landingLayout"
import Brands from "../Components/brands"
import Cta from "../Components/cta"
import { GlobalStyles } from "../Styles/global"
import CommunityHeader from "../Components/community-header"
import ProjectsShowcase from "../Components/projects-showcase"

export default () => (
  <ThemeProvider theme={theme}>
    <Global styles={GlobalStyles} />
    <LandingLayout>
      <CommunityHeader />
      {/* <ProjectsShowcase /> */}
      {/* <Brands /> */}
    </LandingLayout>
  </ThemeProvider>
)
